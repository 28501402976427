import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import FeaturedArticle from "../components/featured-article"
import SliderSwitcherContent from "../components/slider-switcher-content"
import ArticleSlider from "../components/article-slider"
import CtaSlider from "../components/cta-slider"
import ReactLightbox from "../components/react-lightbox"
import PageAdvert from "../components/page-advert"
import Headings from "../components/headings"

const DonationsPage = () => {
  const {
    strapiDonation,
    allStrapiSliderSwitcherContentSection,
    allStrapiArticle,
    allStrapiGallerySection,
  } = useStaticQuery(graphql`
    query {
      strapiDonation {
        hero {
          ...StrapiHero
        }
        headings {
          title
          description
        }
        pageAd {
          ...PageAdverts
        }
        seo {
          ...StrapiSeo
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
        callToAction {
          ...StrapiCallToAction
        }
      }
      allStrapiSliderSwitcherContentSection(
        filter: { location: { eq: "donations-page" }, featured: { eq: true } }
        sort: { order: ASC, fields: sequence }
      ) {
        ...SliderSwitcherContentSections
      }
      allStrapiArticle(
        filter: {
          category: { name: { eq: "Donations" } }
          location: { eq: "donations-page" }
          featured: { eq: true }
        }
        sort: { order: DESC, fields: updatedAt }
      ) {
        nodes {
          ...StrapiArticles
        }
      }
      allStrapiGallerySection(filter: { location: { eq: "donations-page" } }) {
        nodes {
          ...StrapiGallerySection
        }
      }
    }
  `)

  const { hero, seo, headings, pageAd, featuredArticle, callToAction } =
    strapiDonation

  //console.log("pageAd: ", pageAd)

  const snowyRscue = pageAd.page_adverts[0]
  //console.log("pageAd - snowyRscue: ", snowyRscue)
  const Asterix = pageAd.page_adverts[1]
  //console.log("pageAd - snowyRscue: ", snowyRscue)

  const articles = allStrapiArticle.nodes
  //console.log("Articles slider: ", articles)

  const callToActionSections =
    strapiDonation.callToAction.call_to_action_sections

  const sliderSwitcherContent = allStrapiSliderSwitcherContentSection.nodes
  //console.log("sliderSwitcherContent: ", sliderSwitcherContent)

  const gallerySection = allStrapiGallerySection.nodes[0]

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings
        title={headings.title}
        description={headings.description}
        bg="uk-background-default"
      />

      <PageAdvert advert={snowyRscue} bg="uk-background-default" />

      <SliderSwitcherContent
        sliderSwitcherContent={sliderSwitcherContent}
        title="Make a donation today!"
        description="Please see some of the latest successful rescues, and some the dogs in our care that are in most neeed of your continued kindness and support"
        bg="uk-background-muted"
      />

      <PageAdvert advert={Asterix} bg="uk-background-default" />

      <FeaturedArticle
        featuredArticle={featuredArticle}
        title="Featured Article"
        description="The donations we receive for these street dogs are the backbone of our work, thats why this article Why donations are so important is a must read."
        bg="uk-background-muted"
      />

      <ReactLightbox
        images={gallerySection.images}
        location={gallerySection.location}
        title={gallerySection.headings.title}
        description={gallerySection.headings.description}
        bg="uk-background-default"
        //remainingImages={remainingImages}
      />

      <ArticleSlider
        articles={articles}
        title="Featured Stories"
        description="Checkout some of our stories from SaveTheStreetPooches Sri Lanka"
        bg="uk-background-muted"
      />

      <CtaSlider
        title={callToAction.title}
        description={callToAction.description}
        callToActionSections={callToActionSections}
        bg={callToAction.bg}
        image={callToAction.image}
        paypalButton={callToAction.paypalButton}
      />
    </>
  )
}

export default DonationsPage
